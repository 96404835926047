<template>
  <validation-observer ref="simpleRules">
    <b-card-code :title="`Todo List `">
      <!-- <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div> -->
      <app-collapse class="filter mt-1">
        <app-collapse-item title="Filter">

          <b-row class="match-height mb-2">
            <b-col md="6">
              <b-form-group
                label="Date Range"
                label-for="Role"
              >
                <date-range-picker
                  ref="filter"
                  v-model="filterDate"
                  :opens="'right'"
                  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                >
                  <template
                    v-slot:input="picker"
                    style="min-width: 350px;"
                  >
                    {{ picker.startDate | date }} - {{ picker.endDate | date }}
                  </template>
                </date-range-picker>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Status"
                label-for="Status"
              >
                <v-select
                  v-model="filterStatus"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  placeholder="Select Area"
                  :options="optionsStatus"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Area"
                label-for="Area"
              >
                <v-select
                  v-model="filterArea"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  placeholder="Select Area"
                  :options="optionsArea"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Work"
                label-for="Work"
              >
                <v-select
                  v-model="filterWork"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  placeholder="Select Work"
                  :options="optionsWork"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <div>
            <b-button
              variant="outline-primary"
              class="bg-gradient-grey mr-1"
              type="submit"
              @click="resetFilter()"
            >
              <span class="align-middle">Reset</span>
            </b-button>

            <b-button
              variant="outline-primary"
              class="bg-gradient-primary"
              type="submit"
              @click="getDisposisi"
            >
              <span class="align-middle">Filter</span>
            </b-button>
          </div>
        </app-collapse-item>
      </app-collapse>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="dataRows"
        :is-loading="loading"
        style-class="vgt-table striped"
        :line-numbers="true"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          position: 'bottom'
        }"
      >
        <div
          slot="emptystate"
          align="center"
        >
          No Data
        </div>
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Date -->
          <span v-if="props.column.field === 'Date'">
            {{ dateFormat(props.row.Date) }} - {{ dateFormat(props.row.endDate) }}
          </span>

          <!-- Column: Status -->
          <span
            v-else-if="props.column.field === 'Status'"
            :title="props.row.Komentar"
          >
            <b-badge :variant="statusVariant(props.row.Status)">
              {{ props.row.Status }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'Action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none nopad"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <!-- eslint-disable-next-line vue/max-attributes-per-line -->
                <b-dropdown-item @click="detail(props.row.id)">
                  <feather-icon
                    icon="EyeIcon"
                    class="mr-50"
                  />
                  <span>View Detail</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['5','15','30','50']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ totalPage }} entries </span>
            </div>

            <div>
              <template>
                <b-button
                  variant="outline-primary"
                  class="bg-gradient-primary mt-2"
                  pill
                  :disabled="!prev"
                  size="sm"
                  @click="prev ? getDisposisi('prev') : ''"
                >
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </b-button>
              </template>
              <template>
                <b-button
                  variant="outline-primary"
                  class="bg-gradient-primary mt-2 ml-1"
                  pill
                  :disabled="!next"
                  size="sm"
                  @click="next ? getDisposisi('next') : ''"
                >
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </b-button>
              </template>
            </div>
          </div>
        </template>
      </vue-good-table>

      <b-modal
        id="modal-center"
        ref="modal"
        v-model="modalShow"
        centered
        :title="`Update Todo Area ${selectedArea}`"
        cancel-title="Cancel"
        ok-title="Save"
        @ok="validationForm"
      >

        <div class="mt-1 worklist">
          <label> Work List: </label>
          <b> {{ worklist }} </b>
        </div>

        <div v-if="isToday">
          <b-form-checkbox
            v-model="finish"
            checked="true"
            class="mt-2"
            name="check-button"
            switch
            inline
          >
            Finish Work
          </b-form-checkbox>

          <b-form-group
            label="Finish Date"
            label-for="Role"
            class="mt-2"
          >
            <b-form-datepicker
              id="example-datepicker"
              v-model="datePicker"
              class="mb-1"
            />
          </b-form-group>

          <b-form-group
            label="Finish Time"
            label-for="Role"
            class="mt-2"
          >
            <b-form-timepicker
              id="timepicker-buttons"
              v-model="timePicker"
              now-button
              :hour12="false"
              reset-button
              locale="en"
            />
          </b-form-group>
        </div>
        <div
          v-else
          class="invalid"
        >
          <p> Invalid Date </p>
          <hr>
          <p> Belum seusai tanggal selesai checklist </p>
        </div>

        <b-row class="match-height">
          <b-col md="12">
            <b-form-group
              label="Photo Before"
              label-for="Photo Before"
            >
              <div
                v-if="toggleBefore"
                class="preview"
              >
                <img :src="`https://api.arginipratamaputra.com${viewBefore}`">
              </div>

              <b-form-file
                v-else
                id="FotoBefore"
                ref="fileBefore"
                v-model="fileBefore"
                type="file"
                placeholder="Add Photo "
                drop-placeholder="Drop file here..."
                @change="fileBeforeChange"
              />

              <div
                v-show="viewBefore !== '' && viewBefore !== null"
                class="change"
                @click="toggleBefore = !toggleBefore"
              >
                <span> {{ toggleBefore ? 'Change Photo' : 'View Photo' }} </span>
              </div>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Photo Proggress"
              label-for="Photo Proggress"
            >
              <div
                v-if="toggleProggress"
                class="preview"
              >
                <img :src="`https://api.arginipratamaputra.com${viewProggress}`">
              </div>

              <b-form-file
                v-else
                id="FotoProggress"
                ref="fileProggress"
                v-model="fileProggress"
                type="file"
                placeholder="Add Photo "
                drop-placeholder="Drop file here..."
                @change="fileProggressChange"
              />

              <div
                v-show="viewProggress !== '' && viewProggress !== null"
                class="change"
                @click="toggleProggress = !toggleProggress"
              >
                <span> {{ toggleProggress ? 'Change Photo' : 'View Photo' }} </span>
              </div>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Photo After"
              label-for="Photo After"
            >
              <div
                v-if="toggleAfter"
                class="preview"
              >
                <img :src="`https://api.arginipratamaputra.com${viewAfter}`">
              </div>

              <b-form-file
                v-else
                id="FotoAfter"
                ref="filAfter"
                v-model="fileAfter"
                type="file"
                placeholder="Add Photo "
                drop-placeholder="Drop file here..."
                @change="fileAfterChange"
              />

              <div
                v-show="viewAfter !== '' && viewAfter !== null"
                class="change"
                @click="toggleAfter = !toggleAfter"
              >
                <span> {{ toggleAfter ? 'Change Photo' : 'View Photo' }} </span>
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <label for="textarea-default">Notes</label>
        <b-form-textarea
          id="textarea-default"
          v-model="keterangan"
          placeholder="Tulis Catatan"
          rows="3"
        />

      </b-modal>
      <div
        v-if="loadingImage"
        class="load-image"
      >
        <b-spinner
          class="mr-1"
          variant="primary"
        />
      </div>
    </b-card-code>
  </validation-observer>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import Vue from 'vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import moment from 'moment'
import {
  BSpinner,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BLink,
  VBTooltip,
  VBModal,
  BFormTextarea,
  BFormCheckbox,
  BFormDatepicker,
  BFormTimepicker,
  BRow,
  BCol,
} from 'bootstrap-vue'

import { VueGoodTable } from 'vue-good-table'
import axios from '@axios'
// import dayjs from 'dayjs'
// import store from '@/store/index'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, numeric } from '@validations'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dayjs from 'dayjs'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

const isBetween = require('dayjs/plugin/isBetween')

dayjs.extend(isBetween)
Vue.prototype.moment = moment
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormTimepicker,
    BFormDatepicker,
    required,
    email,
    numeric,
    BLink,
    BCardCode,
    VueGoodTable,
    BAvatar,
    vSelect,
    BBadge,
    BFormFile,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BFormTextarea,
    BDropdownItem,
    BButton,
    VBModal,
    VBTooltip,
    BFormCheckbox,
    ToastificationContent,
    AppCollapse,
    AppCollapseItem,
    BRow,
    BCol,
    DateRangePicker,
    BSpinner,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  filters: {
    date(val) {
      return val ? moment(val).format('DD/MM/YYYY') : ''
    },
  },
  data() {
    return {
      pageTitle: '',
      loading: true,
      datePicker: null,
      timePicker: null,
      isToday: false,
      pageLength: 10,
      dir: false,
      totalPage: 0,
      totalRow: 0,
      finish: false,
      viewBefore: null,
      viewProggress: null,
      viewAfter: null,
      fileAfter: null,
      fileProggress: null,
      fileBefore: null,
      toggleBefore: false,
      toggleProggress: false,
      toggleAfter: false,
      loadingImage: false,
      today: '',
      fileName: '',
      keterangan: '',
      worklist: '',
      prev: '',
      next: '',
      page: 0,
      selectedId: 0,
      rangeDate: null,
      selectedItem: [],
      optionsArea: [],
      optionsUser: [],
      selectedUser: [],
      selectedArea: [],
      filterStatus: [],
      filterUser: [],
      filterArea: [],
      filterWork: [],
      filterDate: {
        startDate: null,
        endDate: null,
      },
      optionsStatus: [
        { value: 'PROCESS', text: 'Process' },
        { value: 'DONE', text: 'Done' },
        { value: 'LATE', text: 'Late' },
      ],

      selectedUserID: '',
      selectedAreaID: '',
      fromPage: 0,
      toPage: 0,
      modalShow: false,
      dateRange: {
        startDate: null,
        endDate: null,
      },
      optionsWork: [
        {
          value: '1',
          text: 'Admin',
        },
        {
          value: '2',
          text: 'Employee',
        },
      ],
      columns: [
        {
          label: 'Date',
          field: 'Date',
        },
        {
          label: 'Name',
          field: 'WorkerName',
        },
        {
          label: 'Area',
          field: 'Area',
        },
        {
          label: 'Work',
          field: 'Work',
        },
        {
          label: 'Checklist',
          field: 'ChecklistTime',
        },
        {
          label: 'Action',
          field: 'Action',
        },
      ],
      dataRows: [
        {
          Area: '',
          Checklist: '',
          authorize: false,
          Action: '',
          // Komentar: [],
        },
      ],
      searchTerm: '',
      Status: [
        {
          1: 'Not Done',
          2: 'Finish',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Procces: 'light-primary',
        Finish: 'light-success',
        'Not Done': 'light-danger',
        Resigned: 'light-warning',
        Process: 'light-info',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
  },
  // watch: {
  //   $route() {
  //     console.log(this.$route)
  //     this.getDisposisi()
  //   },
  // },
  mounted() {
    this.getDisposisi()
  },
  methods: {
    async fileBeforeChange(e) {
      this.loadingImage = true
      const formData = new FormData()
      e.target.files.forEach(file => {
        formData.append('foto', file)
      })
      const { data } = await axios.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      this.fileBefore = data
      this.loadingImage = false
    },
    async fileProggressChange(e) {
      this.loadingImage = true
      const formData = new FormData()
      e.target.files.forEach(file => {
        formData.append('foto', file)
      })
      const { data } = await axios.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      this.fileProggress = data
      this.loadingImage = false
    },
    async fileAfterChange(e) {
      this.loadingImage = true
      const formData = new FormData()
      e.target.files.forEach(file => {
        formData.append('foto', file)
      })
      const { data } = await axios.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      this.fileAfter = data
      this.loadingImage = false
    },
    async detail(val) {
      const { data } = await axios.get('/work/assignment', {
        params: {
          id: val,
        },
      })
      this.worklist = data.data[0].work.map(y => y.work).join(', ')
      this.selectedWork = data.data[0].work.map(y => ({
        value: y.id,
        text: y.work,
      }))

      this.viewBefore = data.data[0].foto
      this.toggleBefore = data.data[0].foto !== '' && data.data[0].foto !== null

      this.viewProggress = data.data[0].foto_progress
      this.toggleProggress = data.data[0].foto_progress !== '' && data.data[0].foto_progress !== null

      this.viewAfter = data.data[0].foto_done
      this.toggleAfter = data.data[0].foto_done !== '' && data.data[0].foto_done !== null

      this.dateRange.startDate = data.data[0].start_date
      this.dateRange.endDate = data.data[0].end_date
      this.selectedArea = data.data[0].area.area
      this.selectedId = data.data[0].id
      this.selectedUserID = data.data[0].user.id
      this.selectedAreaID = data.data[0].area.id
      this.datePicker = data.data[0].checklist_time
      this.timePicker = dayjs(data.data[0].checklist_time).format('HH:mm')
      this.finish = data.data[0].checklist !== 0
      this.keterangan = data.data[0].keterangan

      const now = dayjs()
      // dayjs(now).isBetween(
      //   dayjs(this.dateRange.startDate).format('YYYY-MM-DD 00:00:00'),
      //   dayjs(this.dateRange.endDate).format('YYYY-MM-DD 23:59:59'),
      //   now.$y,
      // ) === true

      if (
        dayjs(now).format('DD/MM/YYYY')
        === dayjs(this.dateRange.endDate).format('DD/MM/YYYY')
      ) {
        this.isToday = true
      } else {
        this.isToday = false
      }

      this.modalShow = !this.modalShow
    },

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.updateChecklist()
          this.updateWork()
        }
      })
    },
    async getWork() {
      const { data } = await axios.get('/work')
      this.optionsWork = data.data.map(e => ({
        value: e.id,
        text: e.work,
      }))
    },
    async getDisposisi(val) {
      const defaultUrl = '/work/assignment'
      let url = ''
      if (val === 'next') {
        url = `${defaultUrl}${new URL(this.next).search}`
      } else if (val === 'prev') {
        url = `${defaultUrl}${new URL(this.prev).search}`
      } else {
        url = defaultUrl
      }

      const { data } = await axios.get(url, {
        params: {
          startDate:
            this.filterDate.startDate !== null
              ? dayjs(this.filterDate.startDate).format('YYYY-MM-DD 00:00:00')
              : undefined,
          endDate:
            this.filterDate.endDate !== null
              ? dayjs(this.filterDate.endDate).format('YYYY-MM-DD 23:59:59')
              : undefined,
          user_id: this.filterUser !== null ? this.filterUser.value : undefined,
          area_id: this.filterArea !== null ? this.filterArea.value : undefined,
          'work_id[]':
            this.filterWork !== null ? this.filterWork.value : undefined,
          status:
            this.filterStatus !== null ? this.filterStatus.value : undefined,
        },
      })
      this.prev = data.prev_page_url
      this.next = data.next_page_url
      this.totalPage = data.total
      this.totalRow = data.total
      this.fromPage = data.from
      this.toPage = data.to
      this.pageLength = data.per_page
      // this.today = moment(new Date()).format('DD-MM-YYYY')
      if (data.total !== 0) {
        this.dataRows = data.data.map(e => ({
          id: e.id,
          selectedItem: e,
          Date: e.start_date || '-',
          endDate: e.end_date || '-',
          Area: e.area.area || null,
          WorkerName: e.user.nama || '-',
          Work: e.work.map(y => y.work).join(', ') || '-',
          ChecklistTime:
            e.checklist_time !== null
              ? dayjs(e.checklist_time).format('DD/MM/YYYY - HH:mm')
              : '-',
          Action: '',
        }))
      } else {
        this.dataRows = []
      }
      this.loading = false
      // .catch(error => {
      //   console.log(error)
      // })
    },

    async updateChecklist() {
      await axios
        .post('work/checklist', {
          id: this.selectedId,
          checklist: this.finish,
          checklist_time:
            this.datePicker !== null
              ? `${dayjs(this.datePicker).format('YYYY-MM-DD')} ${
                this.timePicker
              }`
              : null,
        })
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
          this.getDisposisi()
        })
        .catch(error => {
          this.errorLog = error
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
    },

    async updateWork() {
      await axios.post('work/assignment/update', {
        work_id: this.selectedWork.map(e => e.value),
        user_id: this.selectedUserID,
        area_id: this.selectedAreaID,
        checklist_time: `${dayjs(this.datePicker).format('YYYY-MM-DD')} ${
          this.timePicker
        }`,
        done_date: `${dayjs(this.datePicker).format('YYYY-MM-DD')} ${
          this.timePicker
        }`,
        foto: this.fileBefore,
        start_date: this.dateRange.startDate,
        end_date: this.dateRange.endDate,
        foto_progress: this.fileProggress,
        foto_done: this.fileAfter,
        keterangan: this.keterangan,
        id: this.selectedId,
      })
      this.getDisposisi()
    },
    resetFilter() {
      this.filterDate = {
        startDate: null,
        endDate: null,
      }
      // this.filterUser = []
      this.filterArea = []
      this.filterWork = []
      this.filterStatus = []
      this.getDisposisi()
    },

    dateFormat(time) {
      //  const formatted = dayjs(time).format('DD-MM-YYYY (HH:mm)')
      const formatted = dayjs(time).format('DD/MM/YYYY')
      if (formatted === 'Invalid Date') {
        return '-'
      }
      return formatted
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

.nopad {
  padding: 0;
}
.link-no {
  border-bottom: solid 1px #c5c5c5;
  padding-bottom: 3px;
  cursor: pointer;
}

.worklist {
  label,
  b {
    font-size: 13px;
  }
}

.preview {
  img {
    width: 100%;
    display: block;
    margin: auto;
  }
}

.change {
  text-align: center;
  display: flex;
  padding: 9px 0 3px;
  font-weight: bold;
  cursor: pointer;
  justify-content: center;
  color: #00427a;
  span {
    border-bottom: solid 1px #00427a;
  }
}

.vue-daterange-picker {
  width: 100%;
  .reportrange-text {
    display: flex;
    align-items: center;
  }
}

.filter {
  .card-header {
    background: #ededed;
  }
  .card-body {
    border: solid 1px #ededed;
    border-bottom: unset;
  }
}

.invalid {
  text-align: center;
  color: red;
  border: solid 1px;
  border-radius: 5px;
  margin: 20px 0;
  padding: 10px 0 0px;
}
</style>
